/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { SERVER_URL, API_VERSION } from "../../func/constants";
import { copyToBuffer } from "../../func/common";
import api from "../../func/api";
import useDeleteData from "hooks/useDeleteData";
import { useLocation } from "react-router-dom";
import RoundButton from "components/RoundButton/RoundButton";
import { uploadImage } from "helpers/uploadImage";

const UploadMedia = (props) => {
  const { currentPicture, setCurrentPicture, setEditMedia, getData, onDelete } =
    props;

  const location = useLocation();
  const isMediaPage = /\/main\/media($|\/.*)/.test(location.pathname);

  const btnClickEffect = (e) => {
    e.target.classList.add("text-success");
    setTimeout(() => {
      e.target.classList.remove("text-success");
    }, 2000);
  };

  const linkImg = useRef(null);

  const imgLink = `${SERVER_URL + "/" + currentPicture?.url}`;
  const endpointURL = `${SERVER_URL}/api/${API_VERSION}/main/media`;
  const { deleteData: deleteMediaRequest } = useDeleteData(endpointURL);
  return (
    currentPicture !== null && (
      <div className="flexim-media-wrapper d-block">
        <div
          style={{
            padding: "0 4px",
            borderRadius: "10px",
            backgroundColor: "#fff",
            marginBottom: "4px",
          }}
        >
          <div
            className="flexim-uploaded-image"
            style={{
              backgroundImage: "url(" + imgLink + ")",
            }}
          >
            <div className="flexim-image-settings">
              <div
                className="icon-badge text-center pointer"
                style={{
                  color: "#F04343",
                  borderRadius: "8px",
                }}
                onClick={async () => {
                  if (currentPicture._id) {
                    await deleteMediaRequest(currentPicture._id);
                  }

                  setCurrentPicture(null);
                  if (onDelete && onDelete instanceof Function) {
                    await onDelete();
                  }
                }}
              >
                <i className="bi bi-trash" />
              </div>
              <div
                className="icon-badge text-center pointer"
                style={{
                  borderRadius: "8px",
                }}
                onClick={() => {
                  setEditMedia(true);
                }}
              >
                <i className="bi bi-pencil" />
              </div>
              <div
                className="icon-badge text-center pointer"
                style={{
                  borderRadius: "8px",
                }}
                onClick={(e) => {
                  copyToBuffer(imgLink);
                  btnClickEffect(e);
                }}
              >
                <i className="bi bi-link-45deg" />
              </div>
              <div
                className="icon-badge text-center pointer"
                style={{
                  borderRadius: "8px",
                }}
                onClick={(e) => {
                  linkImg.current.click();
                  btnClickEffect(e);
                }}
              >
                <i className="bi bi-box-arrow-in-down" />
                <a
                  href={imgLink}
                  download
                  style={{ opacity: 0, visibility: "hidden" }}
                  ref={linkImg}
                ></a>
              </div>
            </div>
            {!isMediaPage && (
              <RoundButton
                onClick={(e) => {
                  $(e.target).find('input[type="file"]').click();
                }}
                style={{
                  alignSelf: "center",
                }}
              >
                Replace
                <input
                  type="file"
                  name="uploadImg"
                  accept="image/jpeg,image/png,image/svg+xml"
                  onInput={(e) => {
                    uploadImage({
                      event: e,
                      loadAllImagesCallback: getData,
                    });
                  }}
                />
              </RoundButton>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <input
            type="text"
            className="flexim-input"
            style={{
              maxWidth: "410px",
            }}
            defaultValue={currentPicture.alt}
            onChange={(e) => {
              currentPicture.alt = e.target.value;
            }}
            placeholder="Image alt"
            onBlur={() => {
              api
                .postAltMedia({
                  _id: currentPicture._id,
                  alt: currentPicture.alt,
                })
                .then((result) => {})
                .catch((err) => {});
            }}
          />
        </div>
      </div>
    )
  );
};

export default UploadMedia;
