import React from "react";
import { changeData } from "func/common";

import UploadMediaBlock from "../../common/UploadMediaBlock";
import ManyMedia from "../../common/ManyMedia";
import { ReactSortable } from "react-sortablejs";
import Ref from "./fields/Ref";
import Arr from "./fields/Arr";
import Automat from "./fields/Automat";
import RichText from "./fields/RichText";
import DatePicker from "../../common/DatePicker";
import GripIcon from "icons/GripIcon";
import BaseToggler from "components/BaseToggler/BaseToggler";
import Button from "components/Button/Button";
import Select from "components/Select/Select";

import { BorderlessPlusIcon } from "icons";

const switchCaseForFields = ({
  body,
  dispatch,
  data,
  path = [],
  activeIndex,
  namesRefColl,
  setNamesRefColl,
  dataRefColl,
  setDataRefColl,
  rowLength,
  funcOpenMedia,
  funcChooseFromMedia,
  defaultsNames,
  onSave,
}) => {
  // For "component" typed blocks in recursions
  // TODO: Refactor this
  let dataObjInside = path.reduce((obj, p) => {
    if (!obj[p]) obj[p] = {};
    return obj[p] || obj;
  }, data);

  const rowHTMLBlocks = [];

  let fieldValue = dataObjInside[body[activeIndex].nameDB];

  switch (body[activeIndex].typeField) {
    case "automat":
      rowHTMLBlocks.push(
        <div
          className="flexim-field-wrapper"
          key={body[activeIndex]._id}
        >
          <Automat
            data={data}
            path={path}
            dataObjInside={dataObjInside}
            bodyInsaid={body[activeIndex]}
            namesRefColl={namesRefColl}
            dataRefColl={dataRefColl}
            setNamesRefColl={setNamesRefColl}
            defaultsNames={defaultsNames}
            dispatch={dispatch}
          />
        </div>,
      );
      break;
    case "arr":
      rowHTMLBlocks.push(
        <div key={body[activeIndex]._id}>
          <Arr
            changeData={changeData}
            data={data}
            path={path}
            dataObjInside={dataObjInside}
            field={body[activeIndex]}
            namesRefColl={namesRefColl}
            dataRefColl={dataRefColl}
            setNamesRefColl={setNamesRefColl}
            defaultsNames={defaultsNames}
            dispatch={dispatch}
          />
        </div>,
      );
      break;
    case "ref":
      rowHTMLBlocks.push(
        <Ref
          key={body[activeIndex]._id}
          changeData={changeData}
          data={data}
          path={path}
          dataObjInside={dataObjInside}
          bodyInside={body[activeIndex]}
          namesRefColl={namesRefColl}
          dataRefColl={dataRefColl}
          setNamesRefColl={setNamesRefColl}
          defaultsNames={defaultsNames}
          dispatch={dispatch}
        />,
      );
      break;
    case "text":
      if (!fieldValue) {
        dispatch({
          data: changeData({
            data,
            path: [...path, body[activeIndex].nameDB],
            value: { html: [], json: {}, lengthText: 0, text: "" },
          }),
        });
      }
      rowHTMLBlocks.push(
        <RichText
          key={body[activeIndex]._id}
          changeData={changeData}
          data={data}
          path={path}
          dataObjInside={dataObjInside}
          rowLength={rowLength}
          bodyInside={body[activeIndex]}
          dispatch={dispatch}
        />,
      );
      break;
    case "number":
      rowHTMLBlocks.push(
        <div
          key={body[activeIndex]._id}
          className="flexim-field-wrapper"
        >
          <h6
            className={`flexim-field-name${
              body[activeIndex].requiredField ? " required" : ""
            }`}
          >
            {body[activeIndex].name}
          </h6>
          <div className="input-group">
            <input
              placeholder="Use numbers..."
              className="flexim-input"
              type="number"
              value={fieldValue || ""}
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.value,
                  }),
                });
              }}
            />
          </div>
        </div>,
      );
      break;
    case "date":
      rowHTMLBlocks.push(
        <DatePicker
          key={body[activeIndex]._id}
          changeData={changeData}
          data={data}
          path={path}
          dataObjInside={dataObjInside}
          rowLength={rowLength}
          bodyInsaid={body[activeIndex]}
          dispatch={dispatch}
          body={body}
          activeIndex={activeIndex}
        />,
      );
      break;
    case "boolean":
      rowHTMLBlocks.push(
        <div
          className="flexim-field-wrapper"
          key={body[activeIndex]._id}
        >
          <h6
            className={`flexim-field-name${
              body[activeIndex].requiredField ? " required" : ""
            }`}
          >
            {body[activeIndex].name}
          </h6>

          <BaseToggler
            checked={fieldValue}
            onChange={(e) => {
              dispatch({
                data: changeData({
                  data,
                  path: [...path, body[activeIndex].nameDB],
                  value: e.target.checked,
                }),
              });
            }}
          />
        </div>,
      );
      break;
    case "image":
      if (body[activeIndex].isMulti) {
        rowHTMLBlocks.push(
          <ManyMedia
            key={body[activeIndex]._id}
            pics={fieldValue}
            name={body[activeIndex].name}
            isRequired={body[activeIndex].requiredField}
            onChangeArray={(newArr) => {
              dispatch({
                data: changeData({
                  data,
                  path: [...path, body[activeIndex].nameDB],
                  value: newArr,
                }),
              });
            }}
            getData={(imgData) => {
              const imagesParse =
                typeof imgData === "string" ? JSON.parse(imgData) : imgData;
              const images = body[activeIndex].isMulti
                ? imagesParse
                : imagesParse[0];

              const dataProperty = fieldValue;
              const isDataPropertyArray = Array.isArray(dataProperty);

              // Get index or length of the array
              // It is needed to determine the index of the new image in changeData function
              let indexOrLength = undefined;
              if (dataProperty) {
                if (isDataPropertyArray && dataProperty.length) {
                  indexOrLength = dataProperty.length;
                } else if (!isDataPropertyArray) {
                  indexOrLength = Object.keys(dataProperty).length;
                }
              }

              let newValue;
              if (dataProperty) {
                if (isDataPropertyArray && dataProperty.length === 0) {
                  newValue = [images[0]];
                } else {
                  newValue = images[0];
                }
              } else {
                newValue = [images[0]];
              }

              const newPath = [
                ...path,
                body[activeIndex].nameDB,
                indexOrLength,
              ];

              dispatch({
                data: changeData({
                  data,
                  path: newPath,
                  value: newValue,
                }),
              });
            }}
            setEditMedia={(pic, i) => {
              funcOpenMedia(pic, (imgData) => {
                const images =
                  typeof imgData === "string" ? JSON.parse(imgData) : imgData;
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB, i],
                    value: images,
                  }),
                });
              });
            }}
            onDelete={async (newArr) => {
              await dispatch({
                data: changeData({
                  data,
                  path: [...path, body[activeIndex].nameDB],
                  value: newArr,
                }),
              });

              if (onSave && onSave instanceof Function) {
                await onSave();
              }
            }}
          />,
        );
      } else {
        // For the case when we change the type of the field from multi to single
        // Initialy we save multiple images in the array, but here we are not in isMulti mode so we need to save only one image
        // More info: https://app.clickup.com/t/86bzwyukf
        const currentPicture = fieldValue;
        const activePicture = Array.isArray(currentPicture)
          ? null
          : currentPicture;

        if (currentPicture && !activePicture) {
          dispatch({
            data: changeData({
              data,
              path: [...path, body[activeIndex].nameDB],
              value: null,
            }),
          });
        }

        rowHTMLBlocks.push(
          <div key={body[activeIndex]._id}>
            <h6
              className={`flexim-field-name${
                body[activeIndex].requiredField ? " required" : ""
              }`}
              style={{
                marginBottom: "10px",
              }}
            >
              {body[activeIndex].name}
            </h6>
            <UploadMediaBlock
              activePic={activePicture}
              getData={(imgData) => {
                try {
                  const imagesParse =
                    typeof imgData === "string" ? JSON.parse(imgData) : imgData;
                  const images = body[activeIndex].isMulti
                    ? imagesParse
                    : imagesParse[0];

                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: images,
                    }),
                  });
                } catch (error) {
                  console.error(error);
                }
              }}
              setEditMedia={() => {
                funcOpenMedia(fieldValue, (imgData) => {
                  const imagesParse =
                    typeof imgData === "string" ? JSON.parse(imgData) : imgData;

                  const images = body[activeIndex].isMulti
                    ? imagesParse
                    : imagesParse;

                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: images,
                    }),
                  });
                });
              }}
              isCollection={true}
              openChooseMedia={() => {
                funcChooseFromMedia((imgData) => {
                  const images = imgData;
                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: images,
                    }),
                  });
                });
              }}
              onDelete={async () => {
                await dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: null,
                  }),
                });

                if (onSave && onSave instanceof Function) {
                  await onSave();
                }
              }}
            />
          </div>,
        );
      }
      break;
    case "email":
      rowHTMLBlocks.push(
        <div
          key={body[activeIndex]._id}
          className="flexim-field-wrapper flexim-field-wrapper--align-baseline"
        >
          <h6
            className={`flexim-field-name${
              body[activeIndex].requiredField ? " required" : ""
            }`}
          >
            {body[activeIndex].name}
          </h6>
          <div style={{ width: "100%" }}>
            <input
              className="flexim-input"
              type="email"
              value={fieldValue || ""}
              placeholder="Write an e-mail..."
              autoComplete="email"
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.value,
                  }),
                });
              }}
            />
            <label
              htmlFor="email"
              className="email-label text-secondary"
            >
              Must iclude @ and domen in the email adress
            </label>
          </div>
        </div>,
      );
      break;
    case "password":
      rowHTMLBlocks.push(
        <div
          key={body[activeIndex]._id}
          className="flexim-field-wrapper"
        >
          <h6
            className={`flexim-field-name${
              body[activeIndex].requiredField ? " required" : ""
            }`}
          >
            {body[activeIndex].name}
          </h6>
          <div className="input-group">
            <input
              id="password"
              placeholder="******"
              className="flexim-input"
              type="password"
              autoComplete="new-password"
              value={fieldValue}
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: e.target.value,
                  }),
                });
              }}
            />
          </div>
        </div>,
      );
      break;
    case "input":
      if (body[activeIndex].settingField === "textarea") {
        rowHTMLBlocks.push(
          <div
            key={body[activeIndex]._id}
            className="flexim-field-wrapper flexim-field-wrapper--align-baseline"
          >
            <h6
              className={`flexim-field-name${
                body[activeIndex].requiredField ? " required" : ""
              }`}
            >
              {body[activeIndex].name}
            </h6>
            <div className="input-group">
              <textarea
                className="flexim-input"
                placeholder="Write something..."
                value={fieldValue || ""}
                onChange={(e) => {
                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: e.target.value,
                    }),
                  });
                }}
              />
            </div>
          </div>,
        );
      } else {
        rowHTMLBlocks.push(
          <div
            key={body[activeIndex]._id}
            className="flexim-field-wrapper"
          >
            <h6
              className={`flexim-field-name${
                body[activeIndex].requiredField ? " required" : ""
              }`}
            >
              {body[activeIndex].name}
            </h6>
            <div className="input-group">
              <input
                className="flexim-input"
                type="text"
                placeholder="Write something..."
                value={fieldValue || ""}
                onChange={(e) => {
                  dispatch({
                    data: changeData({
                      data,
                      path: [...path, body[activeIndex].nameDB],
                      value: e.target.value,
                    }),
                  });
                }}
              />
            </div>
          </div>,
        );
      }
      break;
    case "select":
      let list =
        fieldValue && body[activeIndex].isMulti
          ? fieldValue?.map((option, i) => {
              return (
                <div
                  key={body[activeIndex]._id + i}
                  className="flexim-tag"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <span>{option}</span>
                  <i
                    className="bi bi-x pointer"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      fieldValue?.splice(i, 1);
                      dispatch({ data });
                    }}
                  />
                </div>
              );
            })
          : [];
      let optionsSelect = [];
      body[activeIndex].selectVars.forEach((e) =>
        fieldValue
          ? fieldValue?.includes(e)
            ? []
            : optionsSelect.push({ label: e, value: e })
          : optionsSelect.push({ label: e, value: e }),
      );

      rowHTMLBlocks.push(
        <div
          className="flexim-field-wrapper flexim-field-wrapper--align-baseline"
          key={body[activeIndex]._id + activeIndex}
        >
          <h6
            className={`flexim-field-name${
              body[activeIndex].requiredField ? " required" : ""
            }`}
          >
            {body[activeIndex].name}
          </h6>
          <div className="w-100">
            <Select
              options={optionsSelect}
              placeholder="Select an option..."
              isControlled
              onChange={(e) => {
                dispatch({
                  data: changeData({
                    data,
                    path: [...path, body[activeIndex].nameDB],
                    value: body[activeIndex].isMulti
                      ? fieldValue
                        ? [...fieldValue, e.value]
                        : [e.value]
                      : e.value,
                  }),
                });
              }}
            />
            {body[activeIndex].isMulti && list?.length > 0 && (
              <div
                className="d-flex"
                style={{ marginTop: "8px", gap: "6px" }}
              >
                {list}
              </div>
            )}
            {!body[activeIndex].isMulti && fieldValue && (
              <label
                className="text-secondary fs-16"
                style={{
                  marginTop: "8px",
                }}
              >
                Selected:{" "}
                <div
                  className="flexim-tag"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <span className="flexim">{fieldValue}</span>
                  <i
                    onClick={(e) => {
                      dispatch({
                        data: changeData({
                          data,
                          path: [...path, body[activeIndex].nameDB],
                          value: null,
                        }),
                      });
                    }}
                    className="bi bi-x pointer"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </div>
              </label>
            )}
          </div>
        </div>,
      );
      break;
    case "component":
      let compData = {};
      let compHTML = [];

      const componentFields = body[activeIndex].component?.fields;

      if (body[activeIndex].isMulti) {
        componentFields?.forEach((elChild) => {
          if (elChild.typeField === "component") {
            if (elChild.isMulti) {
              compData[elChild.nameDB] = [];
            } else {
              compData[elChild.nameDB] = {};
            }
          } else if (elChild.typeField === "image" && elChild.isMulti) {
            compData[elChild.nameDB] = [];
          } else if (elChild.typeField === "boolean") {
            // https://app.clickup.com/t/86bxwz71c
            compData[elChild.nameDB] = false;
          } else if (elChild.typeField === "input") {
            compData[elChild.nameDB] = "";
          }
        });

        let colIndex = 0;
        let rowLengthComp = body[activeIndex].component.cols[colIndex]
          ? body[activeIndex].component.cols[colIndex].length
          : 1;
        let rowLengthCompCounter = rowLengthComp;

        compHTML =
          Array.isArray(fieldValue) &&
          fieldValue?.map((elChild, i) => {
            const childsHTML = [];
            let childsTimeHTML = [];
            componentFields?.forEach((child, iSchema) => {
              if (rowLengthCompCounter === 0) {
                colIndex += 1;
                rowLengthComp = body[activeIndex].component.cols[colIndex]
                  ? body[activeIndex].component.cols[colIndex].length
                  : 1;
                rowLengthCompCounter = rowLengthComp;
                childsHTML.push(
                  <div
                    className="row"
                    key={child.nameDB}
                  >
                    {childsTimeHTML}
                  </div>,
                );
                childsTimeHTML = [];
              }
              rowLengthCompCounter -= 1;
              childsTimeHTML.push(
                switchCaseForFields({
                  body: componentFields,
                  data,
                  dispatch,
                  activeIndex: iSchema,
                  rowLength: rowLengthComp,
                  path: [...path, body[activeIndex].nameDB, i],
                  namesRefColl,
                  setNamesRefColl,
                  dataRefColl,
                  setDataRefColl,
                  funcChooseFromMedia,
                  funcOpenMedia,
                  defaultsNames,
                }),
              );
            });
            childsHTML.push(
              <div
                className="row"
                key={`${elChild.name}-${i}`}
              >
                {childsTimeHTML}
              </div>,
            );
            childsTimeHTML = [];

            return (
              <div
                className="flexim-single-component-wrapper"
                key={body[activeIndex]._id + i}
              >
                <div className="position-relative rounded-start multi-component-left pointer">
                  <GripIcon />
                </div>
                <div className="w-100 flexim-single-component__body flexim-single-component__body--with-right-border">
                  {childsHTML}
                </div>
                <div
                  className="position-relative rounded-end multi-component-right pointer"
                  onClick={() => {
                    fieldValue?.splice(i, 1);
                    dispatch({ data });
                  }}
                >
                  <i className="position-absolute top-50 start-50 translate-middle bi bi-x" />
                </div>
              </div>
            );
          });
      } else {
        compHTML = componentFields?.map((child, i) => {
          return switchCaseForFields({
            body: componentFields,
            data,
            dispatch,
            activeIndex: i,
            rowLength: 1,
            path: [...path, body[activeIndex].nameDB],
            namesRefColl,
            setNamesRefColl,
            dataRefColl,
            setDataRefColl,
            funcChooseFromMedia,
            funcOpenMedia,
            defaultsNames,
          });
        });
      }

      const { _id, requiredField, name } = body[activeIndex];

      const nameElement = (
        <h6
          className={`flexim-field-name flexim-field-name--bold flexim-field-name--inline ${
            requiredField ? "required" : ""
          }`}
        >
          {name}
        </h6>
      );

      rowHTMLBlocks.push(
        body[activeIndex].isMulti ? (
          <div
            className="flexim-single-component"
            key={_id}
          >
            {nameElement}

            <div className="border-0">
              {compHTML?.length ? (
                <ReactSortable
                  animation={200}
                  delayOnTouchStart={true}
                  delay={2}
                  list={fieldValue}
                  setList={(newOrderArr) => {
                    dataObjInside[body[activeIndex].nameDB] = newOrderArr;

                    dispatch({ data });
                  }}
                  group="shared"
                  swapThreshold={0.65}
                  handle=".multi-component-left"
                >
                  {compHTML}
                </ReactSortable>
              ) : (
                <p className="text-secondary text-center">
                  Nothing here yet. Click the button below to add first
                  component
                </p>
              )}
              <div
                className="w-100 d-flex justify-content-center"
                style={{
                  marginTop: compHTML?.length ? "30px" : "16px",
                }}
              >
                <Button
                  icon={<BorderlessPlusIcon />}
                  className="block-group-button"
                  squaredIcon
                  onClick={() => {
                    if (!fieldValue) {
                      dataObjInside[body[activeIndex].nameDB] = [];
                      fieldValue = dataObjInside[body[activeIndex].nameDB];
                    }
                    fieldValue.push(compData);
                    dispatch({ data });
                  }}
                >
                  Add new
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={_id}
            className="flexim-single-component"
          >
            {nameElement}
            <div className="flexim-single-component__body">
              <div className="">{compHTML}</div>
            </div>
          </div>
        ),
      );
      break;
    default:
      break;
  }

  return rowHTMLBlocks;
};

export default switchCaseForFields;
