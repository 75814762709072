import { FC, useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import Endpoints from "components/Endpoints/Endpoints";
import Pagination from "components/Pagination/Pagination";

import CollectionHeader from "pages/Collection/components/CollectionHeader";
import DataTable from "pages/Collection/components/DataTable/DataTable";
import CollectionNavbar from "pages/Collection/components/CollectionNavbar/CollectionNavbar";
import Filters from "pages/Collection/components/Filters/Filters";
import SortingPanel from "pages/Collection/components/Sorting/SortingPanel";
import FieldVisibility from "pages/Collection/components/FieldVisibility/FieldVisibility";
import LegacyModals from "pages/Collection/components/LegacyModals/LegacyModals";

import { selectActivePanel } from "store/selectors/controlPanelSelectors";
import { getFiltersBody } from "store/selectors/filterSelectors";
import { getSavedSortableFields } from "store/selectors/sortingSelectors";
import { pageNameSelector } from "store/selectors/collectionPageInfoSelectors";
import { setCurrentCollectionPageData } from "store/slices/collectionPageInfoSlice";
import {
  fetchCollectionSchema,
  setCurrentViewConfiguration,
} from "store/thunks/collectionPageInfoThunks";

import { buildMongoQuery } from "helpers/filters";
import { parseSortingToMongoQuery } from "helpers/sorting";
import removeEmptyObjectsAndReturnCopy from "utils/removeEmptyObjectsAndReturnCopy";

import {
  useAppDispatch,
  useAppSelector,
  useExcelHandlers,
  useRequiredParam,
  usePrevious,
} from "hooks";
import { collectionApiService } from "api/collectionService";
import { useFetchViewConfigurationsQuery } from "api/apiService";

import { COLLECTION_CONTROL_PANEL, PARAM_COLLECTION_ID } from "constants/index";

import type { ViewConfiguration } from "pages/Collection/components/ViewConfigurations/interfaces/ViewConfiguration";

import { ReactComponent as RocketSVG } from "img/rocket.svg";
import styles from "./Collection.module.scss";

type CollectionProps = {
  typeModel: string;
  cms: string;
};

const apiVersion = process.env.REACT_APP_API_VERSION;

const Collection: FC<CollectionProps> = ({ typeModel, cms }) => {
  const collectionId = useRequiredParam(PARAM_COLLECTION_ID);

  // Export/Import excel handlers
  const { handleExportExcel, handleImportExcel, popup } = useExcelHandlers({
    collectionId,
    currentCMS: cms,
  });

  // Modals
  const [excelSettingsModalOpen, setExcelSettingsModalOpen] = useState(false);
  const [extractModalOpen, setExtractModalOpen] = useState(false);
  const [endPointsModalOpen, setEndPointsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useAppDispatch();
  const collectionName = useAppSelector(pageNameSelector);

  const items = useAppSelector((state) => state.collection.pageInfo.items);
  const totalItems = useAppSelector(
    (state) => state.collection.pageInfo.totalItems
  );

  const filtersBody = useAppSelector(getFiltersBody);
  const sortingBody = useAppSelector(getSavedSortableFields);
  const activePanel = useAppSelector(selectActivePanel);

  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(window.location.search)
  );

  const pageNumber = Number(searchParams.get("page")) || 1;
  const itemsPerPage = Number(searchParams.get("itemsPerPage")) || 20;

  const isTableControlPanelOpened = useMemo(() => {
    return (
      activePanel === COLLECTION_CONTROL_PANEL.FILTERS ||
      activePanel === COLLECTION_CONTROL_PANEL.VISIBILITY
    );
  }, [activePanel]);

  const collectionFields = useAppSelector(
    (state) => state.collection.pageInfo.originalFields
  );

  const {
    data: configurations,
    isLoading: configurationsAreLoading,
    isFetching: configurationsAreFetching,
  } = useFetchViewConfigurationsQuery(collectionId);

  const [state, setState] = useState({
    isCollectionItemsLoading: false,
    isSchemaLoading: true,
    wasConfigurationChanged: false,
    wasInitialViewConfigurationSet: false,
    canFilterOnFrontend: false,
  });

  const {
    isCollectionItemsLoading,
    isSchemaLoading,
    wasConfigurationChanged,
    wasInitialViewConfigurationSet,
    canFilterOnFrontend,
  } = state;

  const handleFetchCollectionItems = useCallback(async () => {
    setState((prev) => ({ ...prev, isCollectionItemsLoading: true }));
    const body = {
      filter: buildMongoQuery(filtersBody),
      sort: parseSortingToMongoQuery(sortingBody),
      pagination: {
        skip: itemsPerPage * (Math.abs(pageNumber) - 1),
        limit: itemsPerPage,
      },
    };
    const processedBody = removeEmptyObjectsAndReturnCopy(body);

    dispatch(
      collectionApiService.endpoints.fetchCollectionDocuments.initiate({
        collectionId,
        params: {
          page: pageNumber,
          search: searchQuery,
        },
        body: processedBody,
      })
    )
      .unwrap()
      .then((collectionPageData) => {
        dispatch(setCurrentCollectionPageData(collectionPageData.data));
      })
      .finally(() => {
        setState((prev) => ({ ...prev, isCollectionItemsLoading: false }));
      });
  }, [
    dispatch,
    collectionId,
    filtersBody,
    sortingBody,
    pageNumber,
    itemsPerPage,
    searchQuery,
  ]);

  // Fetch collection schema
  useEffect(() => {
    if (collectionId) {
      setState((prev) => ({
        ...prev,
        wasInitialViewConfigurationSet: false,
        canFilterOnFrontend: false,
        isSchemaLoading: true,
        isCollectionItemsLoading: true,
      }));
      const currentViewConfigurationId = searchParams.get(
        "viewConfigurationId"
      );

      dispatch(
        fetchCollectionSchema({ collectionId, currentViewConfigurationId })
      )
        .unwrap()
        .finally(() => {
          setState((prev) => ({ ...prev, isSchemaLoading: false }));
        });
    }
  }, [collectionId, searchParams, dispatch]);

  useEffect(() => {
    if (
      !isSchemaLoading &&
      !wasInitialViewConfigurationSet &&
      !configurationsAreLoading &&
      !configurationsAreFetching &&
      configurations?.data
    ) {
      const viewConfigurationId = searchParams.get("viewConfigurationId");

      const configurationIdToSet =
        configurations?.data?.find(
          (configuration) => configuration._id === viewConfigurationId
        )?._id || configurations?.data?.[0]?._id;

      dispatch(
        setCurrentViewConfiguration({
          viewConfigurationId: configurationIdToSet,
          configurations,
        })
      )
        .unwrap()
        .then((configuration: ViewConfiguration | undefined) => {
          setSearchParams((params) => {
            const searchParams = new URLSearchParams(params);
            searchParams.set(
              "viewConfigurationId",
              configuration?._id || configurations?.data?.[0]?._id
            );
            return searchParams.toString();
          });

          setState((prev) => ({
            ...prev,
            wasConfigurationChanged: true,
            wasInitialViewConfigurationSet: true,
          }));
        });
    }
  }, [
    isSchemaLoading,
    wasInitialViewConfigurationSet,
    configurationsAreLoading,
    configurationsAreFetching,
    configurations,
    searchParams,
    dispatch,
    setSearchParams,
  ]);

  const previousFiltersBody = usePrevious(filtersBody);
  const previousSortingBody = usePrevious(sortingBody);

  const isFiltersOrSortingChanged = useMemo(
    () =>
      JSON.stringify(previousFiltersBody) !== JSON.stringify(filtersBody) ||
      JSON.stringify(previousSortingBody) !== JSON.stringify(sortingBody),
    [previousFiltersBody, filtersBody, previousSortingBody, sortingBody]
  );

  const previousPageNumber = usePrevious(pageNumber);
  const previousItemsPerPage = usePrevious(itemsPerPage);

  const isPageNumberOrItemsPerPageChanged = useMemo(() => {
    return (
      previousPageNumber !== pageNumber || previousItemsPerPage !== itemsPerPage
    );
  }, [pageNumber, itemsPerPage, previousPageNumber, previousItemsPerPage]);

  // watch prevValue of searchQuery
  const prevSearchQuery = usePrevious(searchQuery) || "";
  useEffect(() => {
    if (
      (wasConfigurationChanged &&
        wasInitialViewConfigurationSet &&
        !canFilterOnFrontend) ||
      (isFiltersOrSortingChanged && canFilterOnFrontend) ||
      (isPageNumberOrItemsPerPageChanged && canFilterOnFrontend) ||
      prevSearchQuery !== searchQuery
    ) {
      handleFetchCollectionItems().then(() => {
        setState((prev) => ({
          ...prev,
          wasConfigurationChanged: false,
          canFilterOnFrontend: true,
        }));
      });
    }
  }, [
    wasInitialViewConfigurationSet,
    isFiltersOrSortingChanged,
    isPageNumberOrItemsPerPageChanged,
    canFilterOnFrontend,
    handleFetchCollectionItems,
    wasConfigurationChanged,
    prevSearchQuery,
    searchQuery,
  ]);

  const endpointPath = `/api/${apiVersion}/main/data/${typeModel}/${collectionId}`;

  const shouldRenderDataTable = useMemo(() => {
    return (items.length > 0 && collectionFields) || searchQuery;
  }, [items, collectionFields, searchQuery]);

  return (
    <main
      style={{
        paddingTop: "20px",
        fontFamily: "Roboto",
      }}
    >
      {endPointsModalOpen ? (
        <div className="collection-endpoints">
          <Endpoints path={endpointPath} />
        </div>
      ) : null}

      <div
        className="container-fluid"
        style={{ height: "100%" }}
      >
        <div
          className="row"
          style={{ height: "100%" }}
        >
          {collectionId && (
            <div className="col">
              <CollectionHeader
                title={collectionName}
                setEndPointsModalOpen={setEndPointsModalOpen}
                setExtractModalOpen={setExtractModalOpen}
                handleExportExcel={handleExportExcel}
                handleImportExcel={handleImportExcel}
                setExcelSettingsModalOpen={setExcelSettingsModalOpen}
              />
              <div className={styles["view-wrapper"]}>
                <CollectionNavbar collectionId={collectionId} />
                {shouldRenderDataTable && (
                  <DataTable
                    data={items}
                    isLoading={isCollectionItemsLoading}
                    fields={collectionFields}
                    pathToCollection={`/front/${cms}/collections/${collectionId}`}
                    setSearchQuery={setSearchQuery}
                  />
                )}
                {items.length === 0 &&
                  !isCollectionItemsLoading &&
                  !searchQuery && (
                    <div
                      className={`${styles["empty-state"]}${
                        isTableControlPanelOpened
                          ? ` ${styles["empty-state_with-opened-table-control-panel"]}`
                          : ""
                      }`}
                    >
                      <RocketSVG />
                      <span>It’s feel lonely here</span>
                    </div>
                  )}
                <Filters />
                <FieldVisibility />
                <SortingPanel />
              </div>

              <LegacyModals
                typeModel={typeModel}
                progressProps={popup}
                excelSettingsModalOpen={excelSettingsModalOpen}
                setExcelSettingsModalOpen={setExcelSettingsModalOpen}
                extractModalOpen={extractModalOpen}
                setExtractModalOpen={setExtractModalOpen}
              />
            </div>
          )}
        </div>
      </div>
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={pageNumber}
      />
    </main>
  );
};

export default Collection;
