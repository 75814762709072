import React, { useState } from "react";
import api from "../func/api";
import $ from "jquery";

// eslint-disable-next-line react/prop-types
const Login = ({ setUserData }) => {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");

  return (
    <div className="login d-flex">
      <div className="login-win">
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="Login"
            onChange={(e) => {
              setLogin(e.target.value);
            }}
          />
        </div>
        <div className="input-group">
          <input
            className="form-control"
            type="password"
            placeholder="password"
            onChange={(e) => {
              setPass(e.target.value);
            }}
          />
        </div>
        {/* <div className="form-check">
          <input
            className="form-check-input"
            id="flexCheckChecked"
            type="checkbox"
            value=""
            checked=""
          />
          <label className="form-check-label" for="flexCheckChecked">
            Запомнить меня{" "}
          </label>
        </div> */}
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            api
              .login({ login, password: pass })
              .then((login) => {
                if (login.status === 201) {
                  const cmsSlug = Object.keys(login.cmsAccess).find(
                    (key) => login.cmsAccess[key],
                  );
                  if (cmsSlug) window.location.href = "/front/" + cmsSlug;
                } else {
                  $(e.target).addClass("bg-danger");
                  setTimeout(() => {
                    $(e.target).removeClass("bg-danger");
                  }, 2500);
                }
              })
              .catch((err) => {
                console.log(`err`, err);
                $(e.target).addClass("bg-danger");
                setTimeout(() => {
                  $(e.target).removeClass("bg-danger");
                }, 2500);
              });
          }}
        >
          Log In{" "}
        </button>
      </div>
    </div>
  );
};

export default Login;
