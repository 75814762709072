import { useMemo, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { slugify } from "transliteration";

import { sanitizeNameDB } from "comp/pageConstructor/funcs/common";
import styles from "./StartupModal.module.scss";

// TODO: Need to refactor this component (too much callbacks-like props)
export default function StartupModal({
  chooseLockTypeNameBD,
  setChooseLockTypeNameBD,
  lockTypes,
  name,
  setName,
  setIsInitialModalData,
  isInitialModalData,
  typeCreate,
  setDatabaseName,
}) {
  const lockTypesArr = Object.values(lockTypes);

  const [slugValue, setSlugValue] = useState("");

  const [currentLockType, setCurrentLockType] = useState(null);

  useEffect(() => {
    if (isInitialModalData) {
      setName("");
      setSlugValue("");
      setIsInitialModalData(false);
      return;
    }

    const currentName = currentLockType?.name ?? name;
    setName(currentName);
    setSlugValue(slugify(slugValue));
  }, [
    isInitialModalData,
    currentLockType,
    name,
    setName,
    slugValue,
    setIsInitialModalData,
  ]);

  const shouldDisableInputFields = useMemo(() => {
    return currentLockType !== null;
  }, [currentLockType]);

  const cleanString = useCallback((input) => {
    let result = input.replace(/[^a-zA-Z0-9_]+/g, "");
    result = result.replace(/__+/g, "_");
    result = result.replace(/_+$/, "");
    result = result.replace(/(?:\D|^)\d+/g, (match, offset) =>
      offset > 0 && /[a-zA-Z]/.test(result[offset - 1]) ? match : "",
    );
    return result;
  }, []);

  return (
    <div className="modal-body edit-field">
      <div className="row">
        <div className={`col col-${typeCreate === "page" ? "12" : "6"}`}>
          <p className={styles.headline}>Displayed name</p>
          <div className="input-group mb-2">
            <input
              className="flexim-input"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);

                const cleanedString = cleanString(e.target.value);

                const slugifiedValue = sanitizeNameDB(
                  currentLockType?.slug ?? cleanedString,
                );

                setSlugValue(slugifiedValue);
                setDatabaseName(slugifiedValue);
              }}
              disabled={shouldDisableInputFields}
            />
          </div>
          <p className={styles.headline}>Slug</p>
          <div className="input-group mb-2">
            <input
              className="flexim-input"
              type="text"
              value={slugValue}
              disabled
            />
          </div>
        </div>
        {typeCreate !== "page" && lockTypesArr.length > 0 && (
          <div className="col col-6">
            <p className={styles.headline}>Template</p>
            <div className="d-flex">
              <button
                className={`${styles["switch-button"]}${
                  chooseLockTypeNameBD
                    ? ""
                    : ` ${styles["switch-button--active"]}`
                }`}
                onClick={() => {
                  setCurrentLockType(null);
                  setName("");
                  setSlugValue("");
                  setChooseLockTypeNameBD(null);
                }}
              >
                No
              </button>
              {lockTypesArr.map((ltype) => (
                <button
                  key={ltype.nameDB}
                  className={`${styles["switch-button"]}${
                    chooseLockTypeNameBD === ltype.nameDB
                      ? ` ${styles["switch-button--active"]}`
                      : ""
                  }`}
                  onClick={() => {
                    setCurrentLockType(ltype);
                    setChooseLockTypeNameBD(ltype.nameDB);
                    setSlugValue(ltype.nameDB);
                  }}
                >
                  {ltype.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

StartupModal.propTypes = {
  chooseLockTypeNameBD: PropTypes.string,
  setChooseLockTypeNameBD: PropTypes.func,
  lockTypes: PropTypes.object,
  name: PropTypes.string,
  setName: PropTypes.func,
  isInitialModalData: PropTypes.bool,
  setIsInitialModalData: PropTypes.func,
  typeCreate: PropTypes.string,
  setDatabaseName: PropTypes.func,
};
